@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
.styles_header__1zqbi {
  /* background-color: rgb(255, 255, 255); */
  background-color: white;
  height: 60px;
  z-index: 200;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 36px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.styles_logo__ZfVk0 {
  width: 150px;
  cursor: pointer;
}
.styles_userIcon__2-bcy {
  width: 20px;
}
.styles_page-wrap__25VRX {
  min-height: 100vh;
}

/* styles start for Footer.js */
.styles_footer__2qupK {
  width: 100vw;
  background-color: #000;
  color: #fff;
  /* margin-top:250px; */
  padding: 42px 22px 30px 22px;
}
.styles_sitemapContainer__3V4s_ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.styles_subscriptionTitle__CkeD1 {
  font-size: 14px;
  font-weight: bold;
  margin-left: 14px;
}
.styles_subscriptionDescription__2WcNI {
  margin-top: 20px;
  margin-left: 14px;
  font-size: 14px;
  line-height: 1.8;
}
.styles_subscribeContainer__2usEj {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.styles_subscribeInput__2gfWW {
  border: 1px solid #fff;
  color: #fff;
  width: 97%;
  margin-left: 14px !important;
  margin: auto;
  border-radius: 4px;
  padding: 10px 15px;
}
.styles_subscribeBtn__1nbXY {
  margin-top: 24px;
  align-self: center;
  background-color: #fba904;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 14px 24px;
  cursor: pointer;
}
.styles_siteLinksContainer__2ST9s {
  margin-top: 44px;
  margin-left: 14px;
}
.styles_siteLink__3C8yG {
  color: #6f6f6f;
  font-size: 13px;
  margin-bottom: 20px;
  cursor: pointer;
}
.styles_middleLinks__1r_ld {
  margin: 26px 0px;
}
.styles_siteInfo__3JuLu,
.styles_socialLinksContainer__2mmJC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.styles_siteInfo__3JuLu {
  margin-top: 30px;
}
.styles_copyrightText__3PecI {
  font-size: 14px;
}
.styles_socialIcon__3iIaT {
  height: 17px;
  margin-right: 30px;
}
/* styles end for Footer.js */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .styles_header__1zqbi {
    padding: 0px 130px;
    opacity: 1.5;
  }

  .styles_footer__2qupK {
    padding: 47px 5% 30px 8%;
    /* margin-top: 40px; */
    border-top-left-radius: 50px;
  }
  .styles_sitemapContainer__3V4s_ {
    flex-direction: row;
  }
  .styles_subscriptionDescription__2WcNI {
    width: 22vw;
  }
  .styles_subscribeContainer__2usEj {
    flex-direction: row;
    margin-top: 16px;
  }
  .styles_subscribeInput__2gfWW {
    padding: 5px 15px;
  }
  .styles_subscribeBtn__1nbXY {
    margin-top: 0px;
    margin-left: 14px;
    font-size: 14px;
    padding: 8px 15px;
  }
  .styles_siteLinksContainer__2ST9s {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .styles_siteLink__3C8yG {
    margin-bottom: 12px;
  }
  .styles_middleLinks__1r_ld {
    margin: 0px 30px;
  }
}

@media (min-width: 1200px) {
  .styles_footer__2qupK {
    padding: 50px 16% 30px 16%;
    /* margin-top:150px; */
    z-index: +1;
  }
}

.styles_menuPage__2U3Xf {
  margin-top: 20px;
}

body {
  background-color: #fafafc !important;
  overflow-x: hidden;
}

.styles_addPara__13K7v {
  margin-bottom: 10px;
}

.styles_cardHorizontal__1VE1M {
  /* max-width: 400px; */
  height: 200px;
  zoom: 75%;
}

/* hover add */
.styles_cardHorizontal__1VE1M:hover {
  background-color: rgb(247, 247, 247);
  cursor: pointer;
  border: rgb(238, 238, 238) 1px solid;
}

.styles_mobileMenu__2bbT3 {
  margin-top: 25px;
}

.styles_outStockText__LSRqh {
  margin-top: 94px;
  margin-left: 30px;
  margin-right: 20px;
  color: red;
}

.styles_repeatModalDiv__3cjRx {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.styles_modalHead__3XxQ4 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.styles_repeatModalBtn__uvIIy {
  width: 100%;
  border: 1px solid grey;
  padding: 2px 8px;
  border-radius: 2px;
}

.styles_title__A5cXe {
  margin-left: 28px;
  margin-top: 10px;
  width: 140px;
}

.styles_description__1Jy1R {
  margin-left: 28px;
  width: 140px;
  font-size: 10px;
  padding-bottom: 10px;
}

.styles_mobileB__zOwNN {
  margin-top: -20px;
  margin-left: 210px;
}

.styles_drag__3QGeX {
  background: #d8d8d8 0% 0% no-repeat padding-box;
}

.styles_drag2__ugT47 {
  width: 64px;
  height: 8px;
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  margin-left: 155px;
  margin-top: -10px;
}

.styles_resendBtn__3MWuA {
  width: 100%;
  color: blue;
  text-align: end;
  margin-top: 4px;
  font-size: 15px;
}
.styles_reaminderTimer__3vhuD {
  display: block;
  width: 100%;
  text-align: end;
  margin-top: 4px;
  font-size: 15px;
}

.styles_drag3__1mHXr {
  width: 64px;
  height: 8px;
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-top: -10px;
}

.styles_dialog__3mlvu {
  top: 0;
  left: 0px;
  width: 100%;
  height: 300px;
  /* overflow: hidden; */
  overflow-x: hidden;
}

.styles_itemName__1jOeq {
  font-size: 18px;
  padding: 0px;
}

.styles_checkName__19cri {
  width: 34%;
  padding: 0 0;
  display: flex;
  margin-left: 10px;
}

.styles_checkAddBtn__2jtA6 {
  border-radius: 4px;
  width: 50px;
  font-size: 15px;
  margin-left: -5px;
}

.styles_checkBtnDiv__MGYme {
  display: flex;
  flex-direction: row;
  width: 70px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 4px;
}

.styles_checkCount__1ls0X {
  width: 33%;
  display: flex;
  height: 24px;
  background-color: lightblue;
  justify-content: center !important;
  align-items: center !important;
}

.styles_sub__2oGU_ {
  width: 17px;
  height: 17px;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  margin-top: 8px;
  margin-right: 10px;
  color: white;
  text-align: center;
  font-size: 18px;
}

.styles_itemPrice__3HcWQ {
  font-size: 14px;
}

.styles_itemPrice2__1N373 {
  font-size: 14px;
}

.styles_checkoutBtn__1-phO {
  width: 100%;
  background-color: #f14950;
  border-radius: 8px;
  color: white;
  margin-top: 2px;
  /* backgroundColor:"#F14950",borderRadius:"8px",color:"white",marginTop:"2px" */
}

.styles_mymodal__1FZ01 {
  position: fixed;
  top: 50%;
  z-index: 20;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .styles_mymodal__1FZ01 {
    position: fixed;
    top: 50%;
    z-index: 20;
    left: 50%;
    width: 95%;
    min-height: 20%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
}

.styles_closeBtn__2Iwx9 {
  width: 10%;
}

.styles_myoverlay__3f_7H {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(78, 77, 77, 0.55);
  z-index: 100;
}

.styles_toggleButton__1oT9M {
  width: 100% !important;
}

.styles_rightBtn__PuBLM {
  width: 50%;
}

.styles_leftBtn__2K1pr {
  width: 50%;
}

.styles_customText__3-ZHn {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 20px;
}

.styles_textField__JlIYd {
  width: 100%;
  display: flex;
}

.styles_add__2HEth {
  width: 15px;
  height: 17px;
  background: #f14950 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  margin-top: 8px;
  font-size: 6px;
  color: white;
  text-align: center;
  margin-left: 10px;
}

.styles_sub2__3cR9v {
  width: 35px;
  height: 27px;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  margin-top: 8px;
  font-size: 6px;
  color: black;
  padding-top: 4px;
  text-align: center;
  margin-left: 10px;
}

.styles_subtract__3lVJh {
  width: 35px;
  height: 27px;
  background: #f14950 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-top: 8px;
  margin-right: 10px;
  font-size: 6px;
  color: white;
  text-align: center;
  padding: -5px;
}

.styles_priceColorDrag__1Kaa8 {
  color: #414141;
  position: fixed;
  right: 20px;
}

.styles_dragOff__1sl8v {
  top: 262px;
  left: 156px;
  width: 64px;
  height: 4px;
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  margin-left: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.styles_menuBtnDivSingle__3qt_u {
  display: flex;
  border: 1px solid grey;
  height: 40px;
  width: 70%;
  margin-left: 30px;
  border-radius: 4px;
  position: relative;
  bottom: -133px;
  margin-right: 76px;
}

.styles_tip2__BuUwh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  border-radius: 2px;
  width: 20px;
}
.styles_mobileSearch__1Wk2o {
  margin-top: 108px;
  padding: 7px;
}

.styles_mobileInput__1J6g2 {
  width: 100%;
  padding-left: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.styles_mobileimg__3nPlK {
  border-radius: 8px;
  width: 110px;
  height: 110px;
  margin-left: -10px;
}

.styles_category__O-WjW {
  padding: 10px;
  margin-left: 50px;
  font-size: 18px;
  cursor: pointer;
  width: 200px;
}

.styles_category__O-WjW:hover {
  width: 200px;
  border-radius: 4px;
}

.styles_solid__2WLU- {
  border-top: 1px solid #bbb;
}

.styles_modalText__2VqVa {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 20px !important;
}

.styles_price__zk7Tp {
  margin-left: 58%;
  margin-top: 10px;
  font-weight: bold;
}

.styles_priceColor__3lPyh {
  color: #f14950;
}

.styles_dollar__2tckY {
  font-size: 10px;
}

.styles_priceMobileColor__20bah {
  color: #f14950;
  margin-left: 23px;
  margin-top: -4px;
  font-size: 10px;
  font-weight: bold;
}

.styles_btnCountDiv__3sW3N {
  display: flex;
  flex-direction: row;
  width: 24%;
  height: 37px;
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.styles_deskBtnCountDiv__JIK1g {
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 37px;
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.styles_count__1LqFR {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 36px;
  text-align: center;
}

.styles_btnCount__qmGfu {
  width: 33%;
  height: 37px;
  border-radius: 4px;
}

.styles_deleteItem__3l_pw {
  width: 20px;
}
.styles_outStockDiv__2yItl {
  display: flex;
  justify-content: flex-end;
  height: 25px;
  width: 70px !important;
}

.styles_menuBtnDiv__2VnBy {
  display: flex;
  border: 1px solid grey;
  height: 40px;
  width: 70%;
  margin-left: 30px;
  border-radius: 4px;
  position: relative;
  bottom: -94px;
}

.styles_menuShowBtn__1cttR {
  width: 33%;
  font-size: 25px;
}

.styles_menuCountShow__20jsW {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: lightgreen;
}

.styles_orderButton__LUyVs {
  text-align: center;
  color: white;
  border-radius: 4px;
  width: 114px;
  height: 40px;
  position: relative;
  bottom: -100px;
  margin-left: 28px;
  font-size: 18px;
  font-weight: 500;
}
.styles_orderButtonNo__21s4g {
  text-align: center;
  color: white;
  border-radius: 4px;
  width: 114px;
  height: 40px;
  position: relative;
  bottom: -100px;
  margin-left: 28px;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 500;
}

.styles_btnDiv__3gqVv {
  display: flex;
  border: 1px solid grey;
  height: 30px;
  width: 60px;
  margin-right: 40px;
  border-radius: 4px;
}

.styles_mobileButton__2tgi8 {
  position: relative;
  height: 29px;
  width: 33%;
  border-radius: 4px;
}

.styles_mobileCount__2IH1g {
  width: 33%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_checkoutButton__1Y_3K {
  margin-top: 15px;
  padding: 12px 28px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #f14950;
  border-radius: 4px;
  width: 100%;
}

.styles_mobile__6c_cz {
  display: none;
}

.styles_radioForm__3F1YT {
  width: 100% !important;
}

.styles_radioLabel__24Dju {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.styles_checkoutForm__1ALog {
  width: 100% !important;
}

.styles_formlabel__1FFO3 {
  width: 40%;
  font-size: 14px;
}

.styles_checkoutLabel__1E5TM {
  display: flex;
  justify-content: space-between;
}

.styles_priceLabel__1OMql {
  margin-right: 25px !important;
}

.styles_cart__DFdne {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 4px;
  height: auto;
  width: 300px;
  justify-content: space-around;
}

.styles_placeInfo__25WV7 {
  width: 100%;
  background-color: #d9fed3;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  padding-top: 10px;
  border-radius: 4px;
}

.styles_orderTag__3sTtb {
  font-size: 15px;
}

.styles_placeName__2UngT {
  font-weight: 500;
  margin-bottom: 0;
}

.styles_placeAddress__1BU0w {
  font-size: 12px;
  margin-bottom: 20px;
}

.styles_orderItemDiv__3j4x5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 10px;
}

.styles_itemInfo__gDUTY {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.styles_itemName__1jOeq {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.styles_itemPrice__3HcWQ {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 403;
  margin-bottom: 10px;
}

.styles_addOnsName__37qCS {
  font-size: 12px;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.styles_tipDeleteRow__2IwOT {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.styles_addTipRow__1xN-t {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.styles_addTipBtn__1P2s5 {
  width: 30%;
  color: white;
  font-size: 14px;
  border-radius: 3px;
}
.styles_tipInputBox__2SWTx {
  width: 100%;
  padding: 6px 8px;
  font-size: 14px;
  border: 1px solid rgb(180, 180, 180);
  background-color: white;
  border-radius: 5px;
}
.styles_cartBtnDiv__3-9kD {
  display: flex;
  justify-content: space-around;
  border: 1px solid grey;
  border-radius: 4px;
  height: 25px;
  width: 70px !important;
}

.styles_cartBtn__1NQu6 {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.styles_cartCount__2Dn_F {
  background-color: #d6edff;
  height: 23px;
  width: 33%;
  text-align: center;
}

.styles_tipDiv__3UTEK {
  width: 100%;
  background-color: #d9fed3;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.styles_tipDelete__sr2XU {
  width: 20px;
  transition: all 0.5s ease;
}

.styles_tipDelete__sr2XU:hover {
  transform: scale(1.2);
}

.styles_tipPrim__2OhpE {
  font-size: 15px;
  margin-bottom: 10px;
}

.styles_tipSec__1vcsw {
  font-size: 12px;
  margin-top: -15px;
}

.styles_cartMessage__3gunp {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.styles_authHeading__2Fb66 {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 20px !important;
}

.styles_authHeading2__38JMd {
  font-size: 16px !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}

.styles_authRow__1dw77 {
  display: flex;
  justify-content: space-between;
}

.styles_closeAuthBtn__2yP9W {
  margin-top: -25px;
}

.styles_authOtpBtn__aHleW {
  width: 100%;
  margin-top: 20px;
  height: 35px;
  border-radius: 4px;
  color: white;
  background-color: #ffc146;
}

.styles_addOnRow__DHeAb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.styles_boldAdd__37459 {
  font-size: 12px;
  font-weight: 470;
}
.styles_tipList__3hI6z {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.styles_tip__eVIzC {
  cursor: pointer;
  height: 31px;
  width: 60px;
  background-color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgb(189, 189, 189);
}
/* .tip:hover {
  box-shadow: 0 0 40px 40px #e74c3c inset;
} */

.styles_tip__eVIzC:hover {
  background-position: 0;
}

.styles_tipInput__1neZt {
  height: 30px;
  width: 50px;
  background-color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.styles_offersDiv__3REEq {
  display: flex;
  flex-direction: column;
}

.styles_offersHead__5sBhz {
  padding: 0 10px;
  font-weight: 500;
  padding-top: 10px;
  font-size: 15px;
}

.styles_offersRow__d0k0H {
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 13px;
}

.styles_viewOffers__3XtQM {
  cursor: pointer;
  color: #f14950;
}

.styles_priceRow__tu3St {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
}

.styles_divider__2M7ig {
  margin: 0 0;
  margin-bottom: 10px;
}

.styles_subPrim__1mKW_ {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.styles_subValue__9KkkN {
  font-size: 15px;
  margin-bottom: 0;
}

.styles_taxPrim__30Dyp {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 10px;
}

.styles_taxValue__2zmNH {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 0;
}

.styles_primary__1So2N {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.styles_secondary__2GHQA {
  margin-bottom: 10px;
  font-size: 16px;
}

.styles_checkBtn__3dGpd {
  /* position: fixed; */
  /* left: 1015px; */
  margin-top: 20px;
  /* bottom: 20px; */
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px;
  width: 300px;
  height: 40px;
  border-radius: 4px;
  color: white;
}

.styles_mobileMenuBtnDiv__-V-i9 {
  display: flex;
  border: 1px solid grey;
  border-radius: 2px;
  width: 70px;
  height: 27px;
  justify-content: space-between;
}

.styles_mobileMenuShowBtn__3twv3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  font-size: 20px;
}

.styles_mobileMenuCountShow__3wQbP {
  width: 33%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_mobileOrderButton__2OfNK {
  width: 70px;
  height: 27px;
  border: 1px solid white;
  color: white;
  border-radius: 3px;
}

.styles_mobileCart__1OJSR {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: auto;
  justify-content: space-around;
}

.styles_listItem__IadlI {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.styles_listCol__ns--u {
  display: flex;
  width: 60%;
  flex-direction: column;
}

.styles_listItemName__3fjcB {
  margin-bottom: 0;
}

.styles_listCustomItem__nnwbC {
  font-size: 12px;
  margin-bottom: 15px;
}

.styles_subBtnDiv__3hWd8 {
  display: flex;
  width: 20%;
  border: 1px solid grey;
  border-radius: 3px;
  height: 25px;
}

.styles_subShowBtn__2Rgsb {
  width: 33%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_subCountShow__1r0zu {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.styles_myMobileModal__2GznP {
  width: 80%;
  position: fixed;
  top: 50%;
  z-index: 20;
  left: 50%;
  min-height: 30%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.styles_mobileModalBtn__27NF5 {
  margin-top: 10px;
}

.styles_mobileCheckBtn__AZVqZ {
  width: 100%;
  height: 45px;
  color: white;
  font-weight: 400;
  border-radius: 4px;
  font-size: 20px;
}

.styles_mobileModalText__23DxM {
  margin: 0;
  background-color: white;
  font-size: 14px;
  padding-left: 15px;
}

@media (min-width: 552px) {
  .styles_desktop__DOmfy {
    display: none;
  }
  .styles_mobile__6c_cz {
    display: block;
  }
}

@media (max-width: 784px) {
  .styles_radioLabel__24Dju {
    display: flex;
    justify-content: space-between;
  }
  .styles_checkoutLabel__1E5TM {
    display: flex;
  }
  .styles_modalBtn__3TuXb {
    width: 100%;
  }
}

@media (max-width: 494px) {
  .styles_radioLabel__24Dju {
    display: flex;
    justify-content: space-between;
  }
  .styles_checkoutLabel__1E5TM {
    display: flex;
    justify-content: space-between;
  }
}

.styles_modalHeadings__3zyun {
  font-size: 15px;
}

.styles_repeatModalBtn__uvIIy {
  font-size: 15px;
}

@media (max-width: 391px) {
  .styles_radioLabel__24Dju {
    display: flex;
    justify-content: space-between;
  }
  .styles_checkoutLabel__1E5TM {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1013px) {
  .styles_radioLabel__24Dju {
    width: 100% !important;
  }
}

@media (max-width: 1224px) {
  .styles_customDiv__1Agu1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 27px;
    margin-left: -10px;
    margin-right: 10px;
  }
  .styles_btnDiv__3gqVv {
    display: flex;
    border: 1px solid grey;
    height: 30px;
    margin-right: 40px;
  }
  .styles_mobileCustomText__3SNcn {
    margin-right: 0px;
    margin-top: 2px;
    font-size: 10px;
  }
  .styles_mobileOutStockText__1jawK {
    color: red;
    margin-right: 0px;
    margin-top: 2px;
    font-size: 10px;
  }
  .styles_mobileButtonNoCus__2IILx {
    height: 30px;
    width: 30px;
    border-radius: 4px !important;
  }
  .styles_btnDivNoCus__2Q_jX {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 68px;
  }
}

@media (max-width: 453px) {
  .styles_title__A5cXe {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 12px;
    width: 140px;
  }
  .styles_description__1Jy1R {
    margin-left: 10px;
    width: 140px;
    font-size: 10px;
    padding-bottom: 10px;
  }
  .styles_priceMobileColor__20bah {
    color: #f14950;
    margin-left: 7px;
    margin-top: -4px;
    font-size: 10px;
    font-weight: bold;
  }
  .styles_btnDiv__3gqVv {
    margin-right: 20px !important;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Montserrat;
  outline: none !important;
  scroll-behavior: smooth !important;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

.activeCategory {
  background-color: red;
  color: white;
  border-radius: 5pc;
}

*:focus {
  outline: none !important;
}
*:hover {
  outline: none !important;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

hr{
  margin: 0;
  padding: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  background: none;
  border: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Remove all animations and transitions for people that prefer not to see them */
/* @media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
} */

