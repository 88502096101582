.header {
  /* background-color: rgb(255, 255, 255); */
  background-color: white;
  height: 60px;
  z-index: 200;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 36px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.logo {
  width: 150px;
  cursor: pointer;
}
.userIcon {
  width: 20px;
}
.page-wrap {
  min-height: 100vh;
}

/* styles start for Footer.js */
.footer {
  width: 100vw;
  background-color: #000;
  color: #fff;
  /* margin-top:250px; */
  padding: 42px 22px 30px 22px;
}
.sitemapContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subscriptionTitle {
  font-size: 14px;
  font-weight: bold;
  margin-left: 14px;
}
.subscriptionDescription {
  margin-top: 20px;
  margin-left: 14px;
  font-size: 14px;
  line-height: 1.8;
}
.subscribeContainer {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.subscribeInput {
  border: 1px solid #fff;
  color: #fff;
  width: 97%;
  margin-left: 14px !important;
  margin: auto;
  border-radius: 4px;
  padding: 10px 15px;
}
.subscribeBtn {
  margin-top: 24px;
  align-self: center;
  background-color: #fba904;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 14px 24px;
  cursor: pointer;
}
.siteLinksContainer {
  margin-top: 44px;
  margin-left: 14px;
}
.siteLink {
  color: #6f6f6f;
  font-size: 13px;
  margin-bottom: 20px;
  cursor: pointer;
}
.middleLinks {
  margin: 26px 0px;
}
.siteInfo,
.socialLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.siteInfo {
  margin-top: 30px;
}
.copyrightText {
  font-size: 14px;
}
.socialIcon {
  height: 17px;
  margin-right: 30px;
}
/* styles end for Footer.js */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .header {
    padding: 0px 130px;
    opacity: 1.5;
  }

  .footer {
    padding: 47px 5% 30px 8%;
    /* margin-top: 40px; */
    border-top-left-radius: 50px;
  }
  .sitemapContainer {
    flex-direction: row;
  }
  .subscriptionDescription {
    width: 22vw;
  }
  .subscribeContainer {
    flex-direction: row;
    margin-top: 16px;
  }
  .subscribeInput {
    padding: 5px 15px;
  }
  .subscribeBtn {
    margin-top: 0px;
    margin-left: 14px;
    font-size: 14px;
    padding: 8px 15px;
  }
  .siteLinksContainer {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .siteLink {
    margin-bottom: 12px;
  }
  .middleLinks {
    margin: 0px 30px;
  }
}

@media (min-width: 1200px) {
  .footer {
    padding: 50px 16% 30px 16%;
    /* margin-top:150px; */
    z-index: +1;
  }
}

.menuPage {
  margin-top: 20px;
}

body {
  background-color: #fafafc !important;
  overflow-x: hidden;
}

.addPara {
  margin-bottom: 10px;
}

.cardHorizontal {
  /* max-width: 400px; */
  height: 200px;
  zoom: 75%;
}

/* hover add */
.cardHorizontal:hover {
  background-color: rgb(247, 247, 247);
  cursor: pointer;
  border: rgb(238, 238, 238) 1px solid;
}

.mobileMenu {
  margin-top: 25px;
}

.outStockText {
  margin-top: 94px;
  margin-left: 30px;
  margin-right: 20px;
  color: red;
}

.repeatModalDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.modalHead {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.repeatModalBtn {
  width: 100%;
  border: 1px solid grey;
  padding: 2px 8px;
  border-radius: 2px;
}

.title {
  margin-left: 28px;
  margin-top: 10px;
  width: 140px;
}

.description {
  margin-left: 28px;
  width: 140px;
  font-size: 10px;
  padding-bottom: 10px;
}

.mobileB {
  margin-top: -20px;
  margin-left: 210px;
}

.drag {
  background: #d8d8d8 0% 0% no-repeat padding-box;
}

.drag2 {
  width: 64px;
  height: 8px;
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  margin-left: 155px;
  margin-top: -10px;
}

.resendBtn {
  width: 100%;
  color: blue;
  text-align: end;
  margin-top: 4px;
  font-size: 15px;
}
.reaminderTimer {
  display: block;
  width: 100%;
  text-align: end;
  margin-top: 4px;
  font-size: 15px;
}

.drag3 {
  width: 64px;
  height: 8px;
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-top: -10px;
}

.dialog {
  top: 0;
  left: 0px;
  width: 100%;
  height: 300px;
  /* overflow: hidden; */
  overflow-x: hidden;
}

.itemName {
  font-size: 18px;
  padding: 0px;
}

.checkName {
  width: 34%;
  padding: 0 0;
  display: flex;
  margin-left: 10px;
}

.checkAddBtn {
  border-radius: 4px;
  width: 50px;
  font-size: 15px;
  margin-left: -5px;
}

.checkBtnDiv {
  display: flex;
  flex-direction: row;
  width: 70px;
  height: 25px;
  border: 1px solid grey;
  border-radius: 4px;
}

.checkCount {
  width: 33%;
  display: flex;
  height: 24px;
  background-color: lightblue;
  justify-content: center !important;
  align-items: center !important;
}

.sub {
  width: 17px;
  height: 17px;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  margin-top: 8px;
  margin-right: 10px;
  color: white;
  text-align: center;
  font-size: 18px;
}

.itemPrice {
  font-size: 14px;
}

.itemPrice2 {
  font-size: 14px;
}

.checkoutBtn {
  width: 100%;
  background-color: #f14950;
  border-radius: 8px;
  color: white;
  margin-top: 2px;
  /* backgroundColor:"#F14950",borderRadius:"8px",color:"white",marginTop:"2px" */
}

.mymodal {
  position: fixed;
  top: 50%;
  z-index: 20;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .mymodal {
    position: fixed;
    top: 50%;
    z-index: 20;
    left: 50%;
    width: 95%;
    min-height: 20%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
}

.closeBtn {
  width: 10%;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(78, 77, 77, 0.55);
  z-index: 100;
}

.toggleButton {
  width: 100% !important;
}

.rightBtn {
  width: 50%;
}

.leftBtn {
  width: 50%;
}

.customText {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 20px;
}

.textField {
  width: 100%;
  display: flex;
}

.add {
  width: 15px;
  height: 17px;
  background: #f14950 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  margin-top: 8px;
  font-size: 6px;
  color: white;
  text-align: center;
  margin-left: 10px;
}

.sub2 {
  width: 35px;
  height: 27px;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 20px;
  margin-top: 8px;
  font-size: 6px;
  color: black;
  padding-top: 4px;
  text-align: center;
  margin-left: 10px;
}

.subtract {
  width: 35px;
  height: 27px;
  background: #f14950 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-top: 8px;
  margin-right: 10px;
  font-size: 6px;
  color: white;
  text-align: center;
  padding: -5px;
}

.priceColorDrag {
  color: #414141;
  position: fixed;
  right: 20px;
}

.dragOff {
  top: 262px;
  left: 156px;
  width: 64px;
  height: 4px;
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  margin-left: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.menuBtnDivSingle {
  display: flex;
  border: 1px solid grey;
  height: 40px;
  width: 70%;
  margin-left: 30px;
  border-radius: 4px;
  position: relative;
  bottom: -133px;
  margin-right: 76px;
}

.tip2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  border-radius: 2px;
  width: 20px;
}
.mobileSearch {
  margin-top: 108px;
  padding: 7px;
}

.mobileInput {
  width: 100%;
  padding-left: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.mobileimg {
  border-radius: 8px;
  width: 110px;
  height: 110px;
  margin-left: -10px;
}

.category {
  padding: 10px;
  margin-left: 50px;
  font-size: 18px;
  cursor: pointer;
  width: 200px;
}

.category:hover {
  width: 200px;
  border-radius: 4px;
}

.solid {
  border-top: 1px solid #bbb;
}

.modalText {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 20px !important;
}

.price {
  margin-left: 58%;
  margin-top: 10px;
  font-weight: bold;
}

.priceColor {
  color: #f14950;
}

.dollar {
  font-size: 10px;
}

.priceMobileColor {
  color: #f14950;
  margin-left: 23px;
  margin-top: -4px;
  font-size: 10px;
  font-weight: bold;
}

.btnCountDiv {
  display: flex;
  flex-direction: row;
  width: 24%;
  height: 37px;
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.deskBtnCountDiv {
  display: flex;
  flex-direction: row;
  width: 15%;
  height: 37px;
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 4px;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 36px;
  text-align: center;
}

.btnCount {
  width: 33%;
  height: 37px;
  border-radius: 4px;
}

.deleteItem {
  width: 20px;
}
.outStockDiv {
  display: flex;
  justify-content: flex-end;
  height: 25px;
  width: 70px !important;
}

.menuBtnDiv {
  display: flex;
  border: 1px solid grey;
  height: 40px;
  width: 70%;
  margin-left: 30px;
  border-radius: 4px;
  position: relative;
  bottom: -94px;
}

.menuShowBtn {
  width: 33%;
  font-size: 25px;
}

.menuCountShow {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: lightgreen;
}

.orderButton {
  text-align: center;
  color: white;
  border-radius: 4px;
  width: 114px;
  height: 40px;
  position: relative;
  bottom: -100px;
  margin-left: 28px;
  font-size: 18px;
  font-weight: 500;
}
.orderButtonNo {
  text-align: center;
  color: white;
  border-radius: 4px;
  width: 114px;
  height: 40px;
  position: relative;
  bottom: -100px;
  margin-left: 28px;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 500;
}

.btnDiv {
  display: flex;
  border: 1px solid grey;
  height: 30px;
  width: 60px;
  margin-right: 40px;
  border-radius: 4px;
}

.mobileButton {
  position: relative;
  height: 29px;
  width: 33%;
  border-radius: 4px;
}

.mobileCount {
  width: 33%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkoutButton {
  margin-top: 15px;
  padding: 12px 28px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #f14950;
  border-radius: 4px;
  width: 100%;
}

.mobile {
  display: none;
}

.radioForm {
  width: 100% !important;
}

.radioLabel {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.checkoutForm {
  width: 100% !important;
}

.formlabel {
  width: 40%;
  font-size: 14px;
}

.checkoutLabel {
  display: flex;
  justify-content: space-between;
}

.priceLabel {
  margin-right: 25px !important;
}

.cart {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 4px;
  height: auto;
  width: 300px;
  justify-content: space-around;
}

.placeInfo {
  width: 100%;
  background-color: #d9fed3;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  padding-top: 10px;
  border-radius: 4px;
}

.orderTag {
  font-size: 15px;
}

.placeName {
  font-weight: 500;
  margin-bottom: 0;
}

.placeAddress {
  font-size: 12px;
  margin-bottom: 20px;
}

.orderItemDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 10px;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.itemName {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.itemPrice {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 403;
  margin-bottom: 10px;
}

.addOnsName {
  font-size: 12px;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.tipDeleteRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addTipRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.addTipBtn {
  width: 30%;
  color: white;
  font-size: 14px;
  border-radius: 3px;
}
.tipInputBox {
  width: 100%;
  padding: 6px 8px;
  font-size: 14px;
  border: 1px solid rgb(180, 180, 180);
  background-color: white;
  border-radius: 5px;
}
.cartBtnDiv {
  display: flex;
  justify-content: space-around;
  border: 1px solid grey;
  border-radius: 4px;
  height: 25px;
  width: 70px !important;
}

.cartBtn {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.cartCount {
  background-color: #d6edff;
  height: 23px;
  width: 33%;
  text-align: center;
}

.tipDiv {
  width: 100%;
  background-color: #d9fed3;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tipDelete {
  width: 20px;
  transition: all 0.5s ease;
}

.tipDelete:hover {
  transform: scale(1.2);
}

.tipPrim {
  font-size: 15px;
  margin-bottom: 10px;
}

.tipSec {
  font-size: 12px;
  margin-top: -15px;
}

.cartMessage {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.authHeading {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 20px !important;
}

.authHeading2 {
  font-size: 16px !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}

.authRow {
  display: flex;
  justify-content: space-between;
}

.closeAuthBtn {
  margin-top: -25px;
}

.authOtpBtn {
  width: 100%;
  margin-top: 20px;
  height: 35px;
  border-radius: 4px;
  color: white;
  background-color: #ffc146;
}

.addOnRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.boldAdd {
  font-size: 12px;
  font-weight: 470;
}
.tipList {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tip {
  cursor: pointer;
  height: 31px;
  width: 60px;
  background-color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgb(189, 189, 189);
}
/* .tip:hover {
  box-shadow: 0 0 40px 40px #e74c3c inset;
} */

.tip:hover {
  background-position: 0;
}

.tipInput {
  height: 30px;
  width: 50px;
  background-color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.offersDiv {
  display: flex;
  flex-direction: column;
}

.offersHead {
  padding: 0 10px;
  font-weight: 500;
  padding-top: 10px;
  font-size: 15px;
}

.offersRow {
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 13px;
}

.viewOffers {
  cursor: pointer;
  color: #f14950;
}

.priceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
}

.divider {
  margin: 0 0;
  margin-bottom: 10px;
}

.subPrim {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.subValue {
  font-size: 15px;
  margin-bottom: 0;
}

.taxPrim {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 10px;
}

.taxValue {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 0;
}

.primary {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.secondary {
  margin-bottom: 10px;
  font-size: 16px;
}

.checkBtn {
  /* position: fixed; */
  /* left: 1015px; */
  margin-top: 20px;
  /* bottom: 20px; */
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px;
  width: 300px;
  height: 40px;
  border-radius: 4px;
  color: white;
}

.mobileMenuBtnDiv {
  display: flex;
  border: 1px solid grey;
  border-radius: 2px;
  width: 70px;
  height: 27px;
  justify-content: space-between;
}

.mobileMenuShowBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  font-size: 20px;
}

.mobileMenuCountShow {
  width: 33%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileOrderButton {
  width: 70px;
  height: 27px;
  border: 1px solid white;
  color: white;
  border-radius: 3px;
}

.mobileCart {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: auto;
  justify-content: space-around;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listCol {
  display: flex;
  width: 60%;
  flex-direction: column;
}

.listItemName {
  margin-bottom: 0;
}

.listCustomItem {
  font-size: 12px;
  margin-bottom: 15px;
}

.subBtnDiv {
  display: flex;
  width: 20%;
  border: 1px solid grey;
  border-radius: 3px;
  height: 25px;
}

.subShowBtn {
  width: 33%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subCountShow {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.myMobileModal {
  width: 80%;
  position: fixed;
  top: 50%;
  z-index: 20;
  left: 50%;
  min-height: 30%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.mobileModalBtn {
  margin-top: 10px;
}

.mobileCheckBtn {
  width: 100%;
  height: 45px;
  color: white;
  font-weight: 400;
  border-radius: 4px;
  font-size: 20px;
}

.mobileModalText {
  margin: 0;
  background-color: white;
  font-size: 14px;
  padding-left: 15px;
}

@media (min-width: 552px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media (max-width: 784px) {
  .radioLabel {
    display: flex;
    justify-content: space-between;
  }
  .checkoutLabel {
    display: flex;
  }
  .modalBtn {
    width: 100%;
  }
}

@media (max-width: 494px) {
  .radioLabel {
    display: flex;
    justify-content: space-between;
  }
  .checkoutLabel {
    display: flex;
    justify-content: space-between;
  }
}

.modalHeadings {
  font-size: 15px;
}

.repeatModalBtn {
  font-size: 15px;
}

@media (max-width: 391px) {
  .radioLabel {
    display: flex;
    justify-content: space-between;
  }
  .checkoutLabel {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1013px) {
  .radioLabel {
    width: 100% !important;
  }
}

@media (max-width: 1224px) {
  .customDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 27px;
    margin-left: -10px;
    margin-right: 10px;
  }
  .btnDiv {
    display: flex;
    border: 1px solid grey;
    height: 30px;
    margin-right: 40px;
  }
  .mobileCustomText {
    margin-right: 0px;
    margin-top: 2px;
    font-size: 10px;
  }
  .mobileOutStockText {
    color: red;
    margin-right: 0px;
    margin-top: 2px;
    font-size: 10px;
  }
  .mobileButtonNoCus {
    height: 30px;
    width: 30px;
    border-radius: 4px !important;
  }
  .btnDivNoCus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 68px;
  }
}

@media (max-width: 453px) {
  .title {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 12px;
    width: 140px;
  }
  .description {
    margin-left: 10px;
    width: 140px;
    font-size: 10px;
    padding-bottom: 10px;
  }
  .priceMobileColor {
    color: #f14950;
    margin-left: 7px;
    margin-top: -4px;
    font-size: 10px;
    font-weight: bold;
  }
  .btnDiv {
    margin-right: 20px !important;
  }
}
